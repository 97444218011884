import ListItem from "./ListItem";
import introIcon from "../../../../assets/images/services/implementation.svg";
import dbIcon from "../../../../assets/images/services/database.svg";
import devIcon from "../../../../assets/images/services/dev.svg";
import engIcon from "../../../../assets/images/services/engineering.svg";

import styles from "./styles.module.scss";

const Services = ({ serviceRef }: any) => {
  return (
    <div className={styles.services} ref={serviceRef}>
      <h2>What services we provide</h2>
      <div className={styles.serviceList}>
        <ListItem
          icon={introIcon}
          title="Implementation"
          content={[
            "Research – Identification of customer goals and critical processes;",
            "Terms of Reference – Composition of a detailed implementation plan;",
            "Customization – Expanding the existing functionality of E3.series through individual developments;",
            "Customer support – Staff training and technical support.",
          ]}
        />
        <ListItem
          icon={devIcon}
          title="Custom developments"
          content={[
            "Create additional add-ons, applications and modules using an open API that allows you to customize and modify the E3.series system for business or production tasks;",
            "Cross-system integration – Organization of two-way interaction with the environment of ECAD, MCAD, PDM, PLM, ERP and other representatives of the enterprise;",
            "Additional process automation – Description of the entire scenario for performing routine tasks in one click.",
          ]}
        />
        <ListItem
          icon={dbIcon}
          title="Product Data Management (PDM)"
          content={[
            "Unique database – We offer to set up a database, filling it with unique properties of components and sets of attributes of only those manufacturers with which your company periodically works;",
            "Compliance with standards – We ensure that the libraries we provide comply not only with the requirements of standards such as IEC 60950 and ГОСТ, but also with the internal structure of the company.",
          ]}
        />
        <ListItem
          icon={engIcon}
          title="Competencies"
          content={[
            "Design and Engineering - Design of medical grade devices and tools, IP6x standard devices for indoor and outdoor use, Customization of the existing structures and integration, Sheet metal design for Electronics control panels, Mechatronics, High precision electronic devices",
            "Structural and Modal Simulations - Analysis and optimization of constructions, Thermal analysis, Thermal analysis, Drop test and vibrations analysis",
            "Design For Manufacturability - Describing manufacturing process, Research and development of new manufacturing process and order of work",
          ]}
        />
      </div>
    </div>
  );
};

export default Services;
