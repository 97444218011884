import styles from "./styles.module.scss";

const Introduction = ({ handleScroll }: any) => {
  return (
    <div className={styles.introduction}>
      <div className={styles.contentWrapper}>
        <div className={styles.content}>
          <h2>DESIGN CONTINUES WITH US</h2>
          <p>
            Professional automation and solution of a full cycle of design
            problems in electrical engineering
          </p>
          <div>
            <button onClick={() => handleScroll("contacts")}>
              CONNECT WITH US
            </button>
          </div>
          <div className={styles.arrowDown}>
            <svg
              onClick={() => handleScroll("contacts")}
              role="presentation"
              fill="#ffffff"
              x="0px"
              y="0px"
              width="38.417px"
              height="18.592px"
              viewBox="0 0 38.417 18.592"
            >
              <g>
                <path d="M19.208,18.592c-0.241,0-0.483-0.087-0.673-0.261L0.327,1.74c-0.408-0.372-0.438-1.004-0.066-1.413c0.372-0.409,1.004-0.439,1.413-0.066L19.208,16.24L36.743,0.261c0.411-0.372,1.042-0.342,1.413,0.066c0.372,0.408,0.343,1.041-0.065,1.413L19.881,18.332C19.691,18.505,19.449,18.592,19.208,18.592z"></path>
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Introduction;
