import styles from "./styles.module.scss";

const Advantages = () => {
  return (
    <div className={styles.advantages}>
      <div className={styles.content}>
        <h3>Company advantages</h3>
        <div className={styles.listWrapper}>
          <div className={styles.listItem}>
            <div className={styles.iconWrapper}>
              <svg
                role="presentation"
                fill="#f45e51"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
                width={55}
                height={55}
              >
                <path d="M50.2 1.9C23.5 1.9 1.7 23.7 1.7 50.4s21.8 48.5 48.5 48.5 48.4-21.8 48.5-48.5c0-26.7-21.8-48.5-48.5-48.5zm-7.3 71.4L22.7 53.2l4.2-4.3 15.8 15.7 32.1-35.4 4.4 4-36.3 40.1z"></path>
                <path d="M50.2 1.9C23.5 1.9 1.7 23.7 1.7 50.4s21.8 48.5 48.5 48.5 48.4-21.8 48.5-48.5c0-26.7-21.8-48.5-48.5-48.5zm-7.3 71.4L22.7 53.2l4.2-4.3 15.8 15.7 32.1-35.4 4.4 4-36.3 40.1z"></path>
              </svg>
            </div>
            <div className={styles.infoWrapper}>
              <div className={styles.title}>Experience</div>
              <div className={styles.info}>
                Our employees have been supporting <span>E3.series</span>{" "}
                products for over 15 years
              </div>
            </div>
          </div>
          <div className={styles.listItem}>
            <div className={styles.iconWrapper}>
              <svg
                role="presentation"
                fill="#f45e51"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
                width={55}
                height={55}
              >
                <path d="M50.2 1.9C23.5 1.9 1.7 23.7 1.7 50.4s21.8 48.5 48.5 48.5 48.4-21.8 48.5-48.5c0-26.7-21.8-48.5-48.5-48.5zm-7.3 71.4L22.7 53.2l4.2-4.3 15.8 15.7 32.1-35.4 4.4 4-36.3 40.1z"></path>
                <path d="M50.2 1.9C23.5 1.9 1.7 23.7 1.7 50.4s21.8 48.5 48.5 48.5 48.4-21.8 48.5-48.5c0-26.7-21.8-48.5-48.5-48.5zm-7.3 71.4L22.7 53.2l4.2-4.3 15.8 15.7 32.1-35.4 4.4 4-36.3 40.1z"></path>
              </svg>
            </div>
            <div className={styles.infoWrapper}>
              <div className={styles.title}>Uniqueness</div>
              <div className={styles.info}>
                KetSoft LLC is the company that provides training and technical
                support for E3.series customers.
              </div>
            </div>
          </div>
          <div className={styles.listItem}>
            <div className={styles.iconWrapper}>
              <svg
                role="presentation"
                fill="#f45e51"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
                width={55}
                height={55}
              >
                <path d="M50.2 1.9C23.5 1.9 1.7 23.7 1.7 50.4s21.8 48.5 48.5 48.5 48.4-21.8 48.5-48.5c0-26.7-21.8-48.5-48.5-48.5zm-7.3 71.4L22.7 53.2l4.2-4.3 15.8 15.7 32.1-35.4 4.4 4-36.3 40.1z"></path>
                <path d="M50.2 1.9C23.5 1.9 1.7 23.7 1.7 50.4s21.8 48.5 48.5 48.5 48.4-21.8 48.5-48.5c0-26.7-21.8-48.5-48.5-48.5zm-7.3 71.4L22.7 53.2l4.2-4.3 15.8 15.7 32.1-35.4 4.4 4-36.3 40.1z"></path>
              </svg>
            </div>
            <div className={styles.infoWrapper}>
              <div className={styles.title}>Community</div>
              <div className={styles.info}>
                We connect users on our platform{" "}
                <a href="#">portal.e3series.tech</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Advantages;
