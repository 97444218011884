import carImg from "../../../../assets/images/car.png";
import engineImg from "../../../../assets/images/engine.png";

import styles from "./styles.module.scss";

const Suggestion = () => {
  return (
    <div className="content">
      <div className={styles.suggestion}>
        <div className={styles.infoWrapper}>
          <h2>What do we offer?</h2>
          <p>
            KetSoft LLC provides maintenance and technical support services for
            e3.series software.
          </p>
        </div>
        <div className={styles.imgWrapper}>
          <img src={carImg} alt="" />
          <img src={engineImg} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Suggestion;
