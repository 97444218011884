import { useRef, useState } from "react";
import Footer from "../../components/ui/Footer/Footer";
import Header from "../../components/ui/Header/Header";
import MainContent from "../../components/ui/MainContent/MainContent";

const MainPage = () => {
  const aboutRef = useRef();
  const serviceRef = useRef();
  const educationRef = useRef();
  const contactsRef = useRef();
  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleScroll = (section) => {
    switch (section) {
      case "aboutUs":
        aboutRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "services":
        serviceRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "education":
        educationRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "contacts":
        contactsRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      default:
        aboutRef.current.scrollIntoView({ behavior: "smooth" });
        break;
    }
    setIsOpenModal(false);
  };

  return (
    <div>
      <Header
        handleScroll={handleScroll}
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
      />
      <MainContent
        handleScroll={handleScroll}
        aboutRef={aboutRef}
        serviceRef={serviceRef}
        educationRef={educationRef}
      />
      <Footer contactsRef={contactsRef} />
    </div>
  );
};

export default MainPage;
