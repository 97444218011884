import IndustriesCarousel from "../IndustriesCarousel/IndustriesCarousel";
import PopularizationCarousel from "../PopularizationCarousel/PopularizationCarousel";
import AboutUs from "../sections/AboutUs/AboutUs";
import Advantages from "../sections/Advantages/Advantages";
import Education from "../sections/Education/Education";
import Introduction from "../sections/Introduction/Introduction";
import Services from "../sections/Services/Services";
import Suggestion from "../sections/Suggestion/Suggestion";

const MainContent = ({
  aboutRef,
  serviceRef,
  educationRef,
  handleScroll,
}: any) => {
  return (
    <div>
      <Introduction handleScroll={handleScroll} />
      <AboutUs aboutRef={aboutRef} />
      <IndustriesCarousel />
      <Suggestion />
      <Services serviceRef={serviceRef} />
      <Advantages />
      <Education educationRef={educationRef} />
      <PopularizationCarousel />
    </div>
  );
};

export default MainContent;
