import styles from "./styles.module.scss";

type Props = {
  icon: any;
  title: string;
  content: any;
};

const ListItem = ({ icon, title, content }: Props) => {
  return (
    <div className={styles.serviceListItem}>
      <div className={styles.iconWrapper}>
        <img src={icon} alt="" />
      </div>
      <div className={styles.infoWrapper}>
        <div className={styles.title}>{title}</div>
        <div className={styles.info}>
          <ul>
            {content.map((it: string, ix: number) => (
              <li key={ix}>{it}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ListItem;
