import experience from "../../../../assets/images/about-us/experience.svg";
import quality from "../../../../assets/images/about-us/quality.svg";
import styles from "./sytles.module.scss";

const AboutUs = ({ aboutRef }: any) => {
  return (
    <div className={styles.aboutUs} ref={aboutRef}>
      <div className={styles.title}>About Us</div>
      <div className={styles.content}>
        <div className={styles.left}>
          <div>
            <img src={experience} alt="" />
          </div>
          <p>
            Despite the fact that the E3.series software package is a ready-made
            IT solution, its implementation is an integral part of the process
            of integrating CAD into the information system of the customer's
            enterprise. KetSoft has extensive experience in performing E3.series
            implementation tasks at its clients' enterprises.
          </p>
        </div>
        <div className={styles.right}>
          <div>
            <img src={quality} alt="" />
          </div>
          <p>
            The professionalism and experience of the team can significantly
            reduce the implementation period and lead to the successful use of
            the software product. In the process of implementing E3.Series, it
            is possible to expand the existing functionality for the
            requirements of the customer.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
