import styles from "./styles.module.scss";

const Education = ({ educationRef }: any) => {
  return (
    <div className={styles.education} ref={educationRef}>
      <div className={styles.content}>
        <h3>About training</h3>
        <div className={styles.infoWrapper}>
          <p className={styles.desc}>
            The following basic and special courses are available to{" "}
            <span>E3.series</span> users:
          </p>
          <ul>
            <li>
              Basic course for specialists in Energy, process control systems
              and instrumentation, designers and constructors of electrical
              cabinets, panels and consoles – 5 days (40 academic hours)
            </li>
            <li>
              Basic course for specialists in cable-harness design – 4 days (32
              academic hours)
            </li>
            <li>
              Special course in designing the ATX section – 2 days (16 academic
              hours)
            </li>
            <li>
              Special course in designing layout plans – 1 day (8 academic
              hours)
            </li>
            <li>
              Special course on working with the COM interface – 3 days (24
              academic hours)
            </li>
          </ul>
          <p className={styles.epilogue}>
            The special course provides an individual approach to solving
            specific problems; its program can be changed in accordance with the
            interests of the customer.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Education;
