import Carousel from "react-multi-carousel";

import ysuImg from "../../../assets/images/Popularization/ysu.jpg";
import seaImg from "../../../assets/images/Popularization/sea.png";

import styles from "./styles.module.scss";

type CardProps = {
  avatar: any;
  title: string;
  content: string;
  link: {
    href: string;
    title: string;
  };
};

const CarouselCard = ({ avatar, title, content, link }: CardProps) => {
  return (
    <div className={styles.card}>
      <div className={styles.iconWrapper}>
        <img src={avatar} alt={title} />
      </div>
      <div className={styles.infoWrapper}>
        <div className={styles.title}>{title}</div>
        <div className={styles.content}>{content}</div>
        <a href={link.href} target="_blank">
          {link.title}
        </a>
      </div>
    </div>
  );
};

const PopularizationCarousel = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <div className={styles.carouselWrapper}>
      <h2>Popularization of E3.series solutions</h2>
      <Carousel responsive={responsive}>
        <CarouselCard
          avatar={ysuImg}
          title="Yerevan State University"
          content="Faculty of Mathematics and Mechanics"
          link={{
            href: "http://www.ysu.am",
            title: "ysu.am",
          }}
        />
        <CarouselCard
          avatar={seaImg}
          title="College of Science and Engineering of American University of Armenia"
          content="Industrial Engineering and Systems Management"
          link={{
            href: "http://cse.aua.am",
            title: "cse.aua.am",
          }}
        />
      </Carousel>
    </div>
  );
};

export default PopularizationCarousel;
