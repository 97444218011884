import Carousel from "react-multi-carousel";

import atomImg from "../../../assets/images/industries/atom1.png";
import neftImg from "../../../assets/images/industries/neft.jpg";
import zavodImg from "../../../assets/images/industries/zavod.jpg";
import samoletImg from "../../../assets/images/industries/samolet.jpg";
import raketaImg from "../../../assets/images/industries/raketa.jpg";
import avtoImg from "../../../assets/images/industries/avto.jpg";
import transpImg from "../../../assets/images/industries/transp.jpg";
import priborImg from "../../../assets/images/industries/pribor.jpg";
import electroImg from "../../../assets/images/industries/electro.jpg";
import dvigImg from "../../../assets/images/industries/dvig.jpg";
import traktorImg from "../../../assets/images/industries/traktor.jpg";
import korablImg from "../../../assets/images/industries/korabl.jpg";

import styles from "./styles.module.scss";

type CardProps = {
  avatar: any;
  title: string;
  content: string;
};

const CarouselCard = ({ avatar, title, content }: CardProps) => {
  return (
    <div className={styles.card}>
      <div className={styles.iconWrapper}>
        <img src={avatar} alt={title} />
      </div>
      <div className={styles.infoWrapper}>
        <div className={styles.title}>{title}</div>
        <div className={styles.content}>{content}</div>
      </div>
    </div>
  );
};

const IndustriesCarousel = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <div className={styles.carouselWrapper}>
      <h2>Industries</h2>
      <Carousel responsive={responsive}>
        <CarouselCard
          avatar={atomImg}
          title="Green energy"
          content="Sustainable design of renewable energy systems for various types of energy sources based on design catalogs and unified information data model. Release of all types of electrical circuits."
        />
        <CarouselCard
          avatar={neftImg}
          title="Oil and gas and chemical industry"
          content="Implementation of the most complex engineering solutions, production of high-quality design documentation"
        />
        <CarouselCard
          avatar={zavodImg}
          title="Industrial automation"
          content="Comprehensive end-to-end design and development of products that meet regional requirements and safety standards."
        />
        <CarouselCard
          avatar={samoletImg}
          title="Aviation industry"
          content="Development of the circuit design of airplanes, helicopters, UAVs and airships in full, ensuring integration with 3D CAD and production data management systems"
        />
        <CarouselCard
          avatar={raketaImg}
          title="Space industry"
          content="Integrated design of the circuit part (electrical, hydraulic and pneumatic) of RKT projects and all necessary bench and test equipment"
        />
        <CarouselCard
          avatar={avtoImg}
          title="Automobile transport"
          content="A specialized complex for the automotive industry that solves design problems in terms of on-board cable network, pneumatics and hydraulics, instrumentation, automation of auxiliary production"
        />
        <CarouselCard
          avatar={transpImg}
          title="Railway transport"
          content="Design of railway transport equipment (in the field of cable networks, cabinets and switchgear, pneumatics and hydraulics, automation of auxiliary production), including for metro cars"
        />
        <CarouselCard
          avatar={priborImg}
          title="Instrumentation"
          content="Development of complex printed circuit boards and design of electrical parts of devices and harnesses in conjunction with 3D design systems"
        />
        <CarouselCard
          avatar={electroImg}
          title="Electric power industry"
          content="Implementation of a cycle of design tasks in the field of energy in the field of design of relay protection and automation, secondary switching, instrumentation and control systems, automated process control systems, electrical cabinets and low-voltage control units"
        />
        <CarouselCard
          avatar={dvigImg}
          title="Engine manufacturing"
          content="Full implementation of the tasks of the circuit (electrical, hydraulic and pneumatic) part of the projects, transfer of circuit data to a 3D “master model”"
        />
        <CarouselCard
          avatar={traktorImg}
          title="Agricultural machinery"
          content="In a single design space, solving problems of the circuit part of projects, integration with 3D models and PDM/PLM/ERP systems"
        />
        <CarouselCard
          avatar={korablImg}
          title="Shipbuilding"
          content="Implementation of projects on ship electrical, automation, ship electrical equipment, connection of power units"
        />
      </Carousel>
    </div>
  );
};

export default IndustriesCarousel;
